
// @ is an alias to /src
import LogoHeader from "@/components/organisms/LogoHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import ConfirmDowngradePopup from "@/components/popups/invoice/ConfirmDowngradePopup.vue";
import { defineComponent, onMounted, ref, reactive, toRefs, inject } from "vue";
import {
  AccountRepository,
  RepositoryFactory,
  CompanyRepository,
  InvoiceRepository,
} from "@/lib/https";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "PricingPlan",
  components: {
    LogoHeader,
    Footer,
    FlatButton,
    MenuOptionDropdown,
    ConfirmDowngradePopup,
  },
  data() {
    return {
      isOpenDowngradePopup: false,
    };
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const state = reactive({
      stickyHeader: false,
      showPremium: true,
    });
    const tooltipIndex = ref(-1);
    const { t } = useI18n();
    const features = ref([
      {
        name: t("pricingPlan.planFeatures1"),
        freePlan: false,
        freePlan2: false,
        freePlan3: true,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail1"),
      },
      {
        name: t("pricingPlan.planFeatures2"),
        freePlan: false,
        freePlan2: true,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail2"),
      },
      {
        name: t("pricingPlan.planFeatures3"),
        freePlan: false,
        freePlan2: true,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail3"),
      },
      {
        name: t("pricingPlan.planFeatures4"),
        freePlan: false,
        freePlan2: true,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail4"),
      },
      {
        name: t("pricingPlan.planFeatures5"),
        freePlan: false,
        freePlan2: true,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail5"),
      },
      {
        name: t("pricingPlan.planFeatures6"),
        freePlan: false,
        freePlan2: false,
        freePlan3: true,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail6"),
      },
      {
        name: t("pricingPlan.planFeatures7"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail7"),
      },
      {
        name: t("pricingPlan.planFeatures8"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail8"),
      },
      {
        name: t("pricingPlan.planFeatures9"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail9"),
      },
      {
        name: t("pricingPlan.planFeatures10"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail10"),
      },
      {
        name: t("pricingPlan.planFeatures11"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail11"),
      },
      {
        name: t("pricingPlan.planFeatures12"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail12"),
      },
      {
        name: t("pricingPlan.planFeatures13"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail13"),
      },
      {
        name: t("pricingPlan.planFeatures14"),
        freePlan: true,
        freePlan2: false,
        freePlan3: false,
        premiumPlan: true,
        detail: t("pricingPlan.plandetail14"),
      },
    ]);
    const user = ref();
    const { getFullProfile } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);
    const { getCompany } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const { getPlanInfo } =
      RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);

    onMounted(async () => {
      const response = await getFullProfile();

      user.value = response;

      // disable premium
      const company = await getCompany();
      state.showPremium = (await getPlanInfo()).planType == 0;
    });

    return {
      ...toRefs(state),
      isMobile,
      tooltipIndex,
      features,
      user,
    };
  },
  methods: {
    onClickPayment(): void {
      this.$router.push("/payment");
    },

    handleScroll(e: any) {
      const tableBody = this.$refs.tableBody as HTMLElement;
      const sticky = tableBody.offsetTop - 95;

      if (e.target.scrollTop > sticky) {
        this.stickyHeader = true;
      } else {
        this.stickyHeader = false;
      }
    },
    stopPayment() {
      this.isOpenDowngradePopup = true;
    },
    onConfirmDowngrade() {
      this.isOpenDowngradePopup = false;
      this.$router.go(0);
    },
    onDowngradeError() {
      this.isOpenDowngradePopup = false;
    },
  },
});
