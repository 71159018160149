
import { defineComponent, reactive, toRefs } from "vue";

import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { useI18n } from "vue-i18n";
import { InvoiceRepository, RepositoryFactory } from "@/lib/https";
export default defineComponent({
  name: "ConfirmDowngradePopup",
  components: {
    FlatButton,
    Popup,
  },
  emits: ["onConfirm", "onError"],
  setup() {
    const { t } = useI18n();
    const state = reactive({
      messageError: "",
    });
    const { stopStandardPlan } =
      RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);
    return { ...toRefs(state), stopStandardPlan, t };
  },
  methods: {
    async confirm() {
      try {
        const cancel = await this.stopStandardPlan();
        if (!cancel) {
          this.$emit("onError");
          return;
        }
        this.$emit("onConfirm");
      } catch (error) {
        this.$emit("onError");
      }
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
