import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-696b02a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    title: _ctx.$t('invoice.popupDowngradeTitle')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", {
          class: "downgrade-desc",
          innerHTML: _ctx.$t('invoice.downgradeDescription')
        }, null, 8, _hoisted_2),
        _createVNode(_component_FlatButton, {
          text: _ctx.$t('invoice.popupConfirmDowngradeButton'),
          class: "confirm-button",
          onClick: _ctx.confirm
        }, null, 8, ["text", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}